<template>
  <v-scale-transition>
    <kits-panel
      :kits-items="blackKits"
      :breadcrumbs="breadcrumbs"
    >
      <h3 class="headline grey--text text-xs-center hidden-sm-and-down">
        Black
      </h3>
    </kits-panel>
  </v-scale-transition>
</template>

<script>
import security from '@/security'

const formTitle = 'Black'

export default {
  components: {
    KitsPanel: () => import(/* webpackChunkName: "kitsPanel" */ '@/components/KitsPanel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Dealer Kits', disabled: false, to: '/dealer_kits',
        },
        {
          text: formTitle, disabled: true,
        },
      ],
      miniBlackKits: [
        {
          icon: 'mdi-account-star',
          path: '/black_upgrade_dreg',
          title: 'Upgrade DREG',
          desc: 'Become Black Dealer.',
        },
      ],
      clearance: '',
      code: 0,
      height: 'auto',
    }
  },
  computed: {
    b89dbDealer: function () {
      return security.me.mobilePlan() === 'B89' || security.me.mobilePlan() === 'B89DB' ||
        security.me.mobilePlan() === 'B39_PLUS50' || security.me.mobilePlan() === 'B149' ||
        this.b89dbPin || security.me.dealerId() === 'TKKR1' || security.me.dealerId() === 'D000000'
    },
    blackKits: function () {
      return security.me.blackDealer() ? this.fullBlackKits : this.miniBlackKits
    },
    fullBlackKits: function () {
      const menus = []

      if (security.roles(['sales'])) {
        menus.push({
          icon: 'mdi-cast-connected',
          path: '/black_register_subscriber',
          title: 'Register Black',
          desc: 'Register SP, MNP or New Black numbers.',
        })
      }

      menus.push(
        {
          icon: 'mdi-compare',
          path: '/black_cobp',
          title: 'COBP to Black',
          desc: 'Change plan from prepaid to Black.',
        },
        /*
        {
          icon: 'payment',
          path: '/black_pay_bill',
          title: 'Pay Black bill',
          desc: 'Use e-recharge to pay Black bill.',
        },
        */
        {
          icon: 'swap_vert',
          path: '/black_cop',
          title: 'Change Black plan',
          desc: 'Upgrade or downgrade Black plan.',
        },
        {
          icon: 'mdi-pin',
          path: '/black_pin_kits',
          title: 'Black PIN',
          desc: 'Manage package-based Black PIN.',
        },
      )

      /*
      this.b89dbDealer && menus.push(
        {
          icon: 'mdi-pin',
          path: '/black_pin_kits',
          title: 'Black PIN',
          desc: 'Manage package-based Black PIN.'
        }
      )
      */
      return menus
    },
  },
}
</script>
